<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="上传视频"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        style="margin-top:30px;"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="视频">
          <span
            class="tip-text"
          >支持MP4/AVI格式视频，1G以内, 最多9个
          </span>
          <upload-video
            accept=".mp4,.avi"
            :max-count="9"
            :multiple="true"
            :max-size="1024 *1024 *1024"
            @change="handleUpload"
            v-decorator="['attachments',{
              rules:[
                { required: true, message: '请上传视频' },
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="是否为最终版本">
          <a-checkbox value="true" v-decorator="['finished', { valuePropName: 'checked' }]">勾选后视频/遗像任务标记为已完成</a-checkbox>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadVideo from '@/components/Upload/Video'
import { uploadDeathPhotoOrVideoTask } from '@/api/order'
export default {
  name: 'UploadEmceeDraft',
  components: {
    UploadVideo
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'upload_death_video' }),
      submitting: false,
      data: {},
      attachments: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleUpload(fileList) {
      this.attachments = fileList
    },

    // 检查视频
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('视频上传错误')
          return
        }
      }

      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response'),
            type: 'video'
          }
          uploadDeathPhotoOrVideoTask(this.serviceOrderId, data).then(res => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
